<template>
  <Header
    ref="headerRef"
    type="CRYPTO_ASSETS_ROLL_FORWARD"
    :title="t('menus.Reports')"
    :showRegenerateButton="false"
    :updatedOn="updatedTime ?? ''"
  />
  <ReportsMenus
    ref="ReportsMenusRef"
    :type="exportProgressStatusType.rollForward.reportValue"
    :title="t('project.nav.rollForward')"
    :params="params"
    @onChangDateTime="onChangDateTime"
  />
  <ExportDataTaskHistoryInfo :exportType="exportProgressStatusType.rollForward" :isMarginTop="true" />
  <div v-loading="loading" class="elv-reports-roll-forward-content">
    <FinancialStatement
      financial-type="roll-forward"
      :params="params"
      :isShowRegeneratedInfo="ReportsMenusRef?.isShowRegeneratedInfo"
    />
  </div>
</template>
<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import Header from '../components/Header.vue'
import ReportsMenus from '../../components/ReportsMenus.vue'
import { useReportStore } from '@/stores/modules/reports/index'
import FinancialStatement from '../components/FinancialStatement.vue'
import ExportDataTaskHistoryInfo from '../components/ExportDataTaskHistoryInfo.vue'
import { exportProgressStatusType } from '@/config/global'

const { t } = useI18n()
const route = useRoute()
const reportStore = useReportStore()
const { financialData, loading } = storeToRefs(reportStore)
const headerRef = useTemplateRef('headerRef')
const ReportsMenusRef = useTemplateRef<InstanceType<typeof ReportsMenus>>('ReportsMenusRef')

const updatedTime = computed(() => {
  return financialData.value.updatedOn
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const params = computed(() => {
  return {
    period:
      ReportsMenusRef.value?.periodValue === 'date' ? 'DAY' : ReportsMenusRef.value?.periodValue.toLocaleUpperCase(),
    dateRange: ReportsMenusRef.value?.dateValue
  }
})

const onChangDateTime = async (data: object) => {
  await reportStore.financialsDataInit(entityId.value, 'roll-forward', data)
}
</script>
<style lang="scss" scoped>
.elv-reports-roll-forward-content {
  min-height: 0px;
  flex: 1;
  position: relative;
}
</style>
